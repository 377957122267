import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import CellValue from 'exceljs/index';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';


@Injectable({
  providedIn: "root"
})
export class ExportService {

  currencyFmt = '# ##0 Kč';
  kwFmt = '# ##0 kW';


  createTableFromObjects(objects: unknown[], excludeObjects = false): CellValue[][] {
    const table: CellValue[][] = [];
    // If there are no objects, return an empty table
    if (objects.length === 0) {
      return table;
    }
    // Add the keys as the first row
    const keys = Object.keys(objects[0]).filter(key => !(typeof objects[0][key] === 'object' && excludeObjects));
    table.push(keys);
    // Add a row with the values of the object
    for (const obj of objects) {
      const row = keys.map(key => {
        // If the value is an object and excludeObjects is true, return an empty string
        if (typeof obj[key] === 'object' && excludeObjects) {
          return '';
        }
        // Otherwise, return the string representation of the value
        return obj[key].toString();
      });
      table.push(row);
    }
    return table;
  }

  numberToLetter(n: number): string {
    let result = '';
    while (n > 0) {
      const remainder = (n - 1) % 26;
      result = String.fromCharCode(65 + remainder) + result;
      n = Math.floor((n - remainder) / 26);
    }
    return result;
  }

  exportReportAsExcelFile(meters: unknown[], fileName = 'export'): void {
    const table = this.createTableFromObjects(meters, true);

    const workbook = new Workbook();
    workbook.creator = 'EnergyWise software';
    workbook.lastModifiedBy = 'neznámý'; // TODO: Přidat jmeno uživatele, který soubor vyexportuje
    workbook.created = new Date();
    workbook.modified = new Date();

    const vycetka = workbook.addWorksheet('Výčetka', {pageSetup: {fitToPage: true}});
    let rowNumber = 1;
    let cellNumber = 1;
    table.forEach(row => {
      row.forEach(cell => {
        vycetka.getCell(this.numberToLetter(cellNumber)+rowNumber).value = cell;
        cellNumber++;
      });
      rowNumber++;
      cellNumber = 1;
    });

    vycetka.getColumn(table[0].length).numFmt = this.currencyFmt;
    vycetka.getColumn(table[0].length-1).numFmt = this.kwFmt;

      workbook.xlsx.writeBuffer().then((data: ArrayBuffer) => {
        const blob = new Blob([data], {type: EXCEL_TYPE});
        fs.saveAs(blob, fileName + EXCEL_EXTENSION);
      });
  }
}
