import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {OverlayModule} from '@angular/cdk/overlay';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';
import {PerfectScrollbarModule} from "ngx-perfect-scrollbar";
import {ClickOutsideModule} from 'ng-click-outside';

import {AutocompleteModule} from './components/autocomplete/autocomplete.module';
import {PipeModule} from 'app/shared/pipes/pipe.module';

//COMPONENTS
import {FooterComponent} from "./footer/footer.component";
import {NavbarComponent} from "./navbar/navbar.component";
import {HorizontalMenuComponent} from './horizontal-menu/horizontal-menu.component';
import {VerticalMenuComponent} from "./vertical-menu/vertical-menu.component";
import {NotificationSidebarComponent} from './notification-sidebar/notification-sidebar.component';

//DIRECTIVES
import {ToggleFullscreenDirective} from "./directives/toggle-fullscreen.directive";
import {SidebarLinkDirective} from './directives/sidebar-link.directive';
import {SidebarDropdownDirective} from './directives/sidebar-dropdown.directive';
import {SidebarAnchorToggleDirective} from './directives/sidebar-anchor-toggle.directive';
import {SidebarDirective} from './directives/sidebar.directive';
import {TopMenuDirective} from './directives/topmenu.directive';
import {TopMenuLinkDirective} from './directives/topmenu-link.directive';
import {TopMenuDropdownDirective} from './directives/topmenu-dropdown.directive';
import {TopMenuAnchorToggleDirective} from './directives/topmenu-anchor-toggle.directive';
import {OverviewChartComponent} from "./components/overview-chart/overview-chart.component";
import {ChartistModule} from "ng-chartist";
import {PowerflowDiagramComponent} from "./components/powerflow-diagram/powerflow-diagram.component";
import {ForecastModuleComponent} from "./components/forecast-module/forecast-module.component";
import {InstallationReportModuleComponent} from "./components/installation-report-module/installation-report-module.component";
import {NgxDatatableModule} from "@swimlane/ngx-datatable";
import {InstallationReportTableComponent} from "./components/installation-report-module/Installation-report-table/Installation-report-table.component";
import {NgApexchartsModule} from "ng-apexcharts";


@NgModule({
  exports: [
    CommonModule,
    FooterComponent,
    NavbarComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    NotificationSidebarComponent,
    ToggleFullscreenDirective,
    SidebarDirective,
    TopMenuDirective,
    NgbModule,
    TranslateModule,
    OverviewChartComponent,
    PowerflowDiagramComponent,
    ForecastModuleComponent,
    InstallationReportModuleComponent,
    InstallationReportTableComponent
  ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule,
        TranslateModule,
        FormsModule,
        OverlayModule,
        ReactiveFormsModule,
        PerfectScrollbarModule,
        ClickOutsideModule,
        AutocompleteModule,
        PipeModule,
        ChartistModule,
        NgxDatatableModule,
        NgApexchartsModule,
    ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    NotificationSidebarComponent,
    ToggleFullscreenDirective,
    SidebarLinkDirective,
    SidebarDropdownDirective,
    SidebarAnchorToggleDirective,
    SidebarDirective,
    TopMenuLinkDirective,
    TopMenuDropdownDirective,
    TopMenuAnchorToggleDirective,
    TopMenuDirective,
    OverviewChartComponent,
    PowerflowDiagramComponent,
    ForecastModuleComponent,
    InstallationReportModuleComponent,
    InstallationReportTableComponent
  ]
})
export class SharedModule {
}
