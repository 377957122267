import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { shareReplay, tap } from 'rxjs/operators';
import { UserModel, UserRole } from '../models/User.model';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { UserService } from '../services/user.service';
import {InstallationService} from '../services/installation.service';

@Injectable()
export class AuthService {
  public user: BehaviorSubject<UserModel | null> = new BehaviorSubject<UserModel | null>(null);

  constructor(
    private http: HttpClient,
    public router: Router,
    public userService: UserService,
    public installationService: InstallationService
  ) {}

  signinUser(email: string, password: string) {
    return this.http.post<UserModel>(environment.apiHost + '/auth/login', {email, password}).pipe(
      tap(res => this.setSession(res)),
      shareReplay(1)
    );
  }

  logout() {
    this.user.next(null);
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    this.router.navigate(['/login']).then();
  }

  isAuthenticated():boolean {
    const token = localStorage.getItem("id_token");
    return !!token && this.isLoggedIn();
  }

  private setSession(authResult) {
    const expiresAt = moment().add(authResult.expiresIn,'millisecond');
    localStorage.setItem('id_token', authResult.token);
    localStorage.setItem("expires_at", JSON.stringify(expiresAt.valueOf()));
  }

  public isLoggedIn() {
    if(moment().isBefore(this.getExpiration())) {
      this.userService.getMe().subscribe(result => {
        this.user.next(result)
        this.installationService.selectedInstallation.next(result.installations[0]);
      });
      return true;
    } else {
      this.logout();
      return false;
    }
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getExpiration() {
    const expiration = localStorage.getItem("expires_at");
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  canView(userRole: UserRole, role: UserRole): boolean {
    const roleHierarchy = [UserRole.ROLE_USER, UserRole.ROLE_STAFF, UserRole.ROLE_TECHNICIAN, UserRole.ROLE_ADMIN];
    const userRoleIndex = roleHierarchy.indexOf(userRole);
    const requiredRoleIndex = roleHierarchy.indexOf(role);
    return (userRoleIndex >= requiredRoleIndex);
  }
}
