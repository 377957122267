import {CanActivate, Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthService} from '../auth/auth.service';
import {Observable} from 'rxjs';
import {UserRole} from '../models/User.model';

@Injectable()
export class AdminGuard implements CanActivate {

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve) => {
      this.authService.user.subscribe(user => {
        if (user) {
          if (user.role == UserRole.ROLE_ADMIN) {
            return resolve(true);
          } else {
            this.router.navigate(['/dashboard']);
            return resolve(false);
          }
        }
      });
    });
  }
}
