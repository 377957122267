<section *ngIf="data.length !== 0 else fetching" id="ngx-datatable-filter">
  <div class="row">
    <div class="col-12">
      <fieldset class="row form-group d-flex justify-content-between">
        <label class="col-12 col-lg-4 mt-2 d-flex align-items-center justify-content-center justify-content-lg-start">
          Show
          <select
            [(ngModel)]="limitRef"
            name="users-list-select"
            class="form-control form-control-sm d-inline-block width-100 p-right-25 pl-4 mx-1">
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
          entries
        </label>
        <label class="col-12 col-md-6 col-lg-4 mt-2 d-flex align-items-center justify-content-center justify-content-lg-center justify-content-md-start" for="ngx-filter-ref">Search:
          <input id="ngx-filter-ref" class="form-control form-control-sm d-inline-block width-200 ml-1"
                 type="text" [(ngModel)]="filterInput" (keyup)="updateRows()"/>
        </label>
        <label class="col-12 col-md-6 col-lg-4 mt-2 d-flex align-items-center justify-content-center justify-content-md-end" for="ngx-filter-ref">Price:
          <input class="form-control form-control-sm d-inline-block width-200 ml-1"
                 type="text" [(ngModel)]="priceInput" (keyup)="updateRows()"/>
        </label>
      </fieldset>
      <ngx-datatable
        class="bootstrap core-bootstrap"
        [columnMode]="ColumnMode.force"
        [headerHeight]="50"
        [footerHeight]="50"
        rowHeight="auto"
        [limit]="limitRef"
        [rows]="(rows | async)"
        [scrollbarH]="true">
<!--        (activate)="rowNavigate($event)"-->
<!--      >-->
        <ngx-datatable-column [sortable]="false" name="ID" prop="id" [width]="50"></ngx-datatable-column>
        <ngx-datatable-column [sortable]="false" name="Name" prop="name" [width]="50"></ngx-datatable-column>
        <ngx-datatable-column [sortable]="false" name="W" prop="consumption" [width]="50"></ngx-datatable-column>
        <ngx-datatable-column [sortable]="false" name="Cost" prop="cost" [width]="50"></ngx-datatable-column>
      </ngx-datatable>
    </div>
  </div>
</section>
<hr>
<div *ngIf="(rows | async).length !== 0" class="text-center d-flex flex-md-row flex-column justify-content-center">
  <div class="text-nowrap">
    PRODUCTION
    <span class="text-success"><i class="ft-arrow-down-left"></i> {{ (production | async) | powerConversion }}</span>
  </div>
  <div class="d-md-block d-none mx-3">|</div>
  <div class="text-nowrap">
    GRID
    <span class="text-success"><i class="ft-arrow-up-right"></i> {{ (toGrid | async) | powerConversion }}</span>
    <span class="text-danger ml-2"><i class="ft-arrow-down-left"></i> {{ (fromGrid | async) | powerConversion }}</span>
  </div>
  <div class="d-md-block d-none mx-3">|</div>
  <div class="text-nowrap">
    BATTERY
    <span class="text-warning"><i class="ft-arrow-up-right"></i> {{ (fromBattery | async) | powerConversion }}</span>
    <span class="text-info ml-2"><i class="ft-arrow-down-left"></i> {{ (toBattery | async) | powerConversion }}</span>
  </div>
</div>


<ng-template #fetching>
  <p class="ml-3">Fetching data...</p>
</ng-template>
