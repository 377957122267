import {Component, Input, OnChanges, SimpleChanges, ViewChild, ViewEncapsulation} from "@angular/core";
import {DatatableComponent, ColumnMode} from "@swimlane/ngx-datatable";
import {BehaviorSubject} from "rxjs";
import {ExportService} from "../../../services/export.service";
import {take} from "rxjs/operators";
import {PeriodType} from "../../../models/PeriodType.enum";
import {InstallationOverviewTable} from '../../../models/InstallationOverviewTable.interface';

@Component({
  selector: "app-installation-report-table",
  templateUrl: "./Installation-report-table.component.html",
  styleUrls: [
    "./Installation-report-table.component.scss",
    "../../../../../assets/sass/libs/datatables.scss",
  ],
  encapsulation: ViewEncapsulation.None,
})
export class InstallationReportTableComponent implements OnChanges {
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @Input() interval= "";
  @Input() intervalLength:PeriodType = PeriodType.DAY;
  @Input() tableData: BehaviorSubject<InstallationOverviewTable>;
  rows = new BehaviorSubject<{
    id:number,
    name:string;
    consumption: number;
    cost: number;
  }[]>([]);
  production= new BehaviorSubject<number>(0);
  toGrid = new BehaviorSubject<number>(0);
  fromGrid = new BehaviorSubject<number>(0);
  toBattery = new BehaviorSubject<number>(0);
  fromBattery = new BehaviorSubject<number>(0);
  ColumnMode = ColumnMode;
  limitRef = 10;
  data = [];

  priceInput = 0;
  filterInput = "";


  constructor(
    private exportService: ExportService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tableData && this.tableData) {
        this.tableData.subscribe(result => {
          if(result){
            this.rows.next(result.table);
            this.data = result.table;
            this.production.next(result.totalProduction);
            this.toGrid.next(result.totalToGrid);
            this.fromGrid.next(result.totalFromGrid);
            this.toBattery.next(result.totalToBattery);
            this.fromBattery.next(result.totalFromBattery);
            this.updateRows();
          } else {
            this.rows.next([]);
            this.data = [];
            this.production.next(0);
            this.toGrid.next(0);
            this.fromGrid.next(0);
            this.toBattery.next(0);
            this.fromBattery.next(0);
          }
        });
    }
  }

  updateRows(): void {
    const val = this.filterInput.toLowerCase();
    const filteredData = this.data.filter(d => d.name.toLowerCase().indexOf(val) !== -1 || !val);
    const totalConsumption = this.data.reduce((sum, meter) => sum + meter.consumption, 0);

    this.rows.next(
      filteredData.map(meter => {
        const cost = totalConsumption !== 0 ? +((meter.consumption / totalConsumption) * this.priceInput).toFixed(2) : 0;
        return { ...meter, cost };
      })
    );
  }

  // rowNavigate(event): void {
  //   // if (event.type === 'click') {
  //   //   const meter = event.row;
  //   //   this.router.navigate(['/installation/' + this.instalationID + '/meter/' + meter.id]);
  //   // }
  // }

  exportToExcel(): void {
    this.rows.pipe(take(1)).subscribe(meters => {
      this.exportService.exportReportAsExcelFile(meters, this.intervalLength + '_' + this.interval + '_report');
    });
  }
}
