import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {ChartService} from '../../services/chart.service';
import {ChartOptions, themeColors} from "../../models/CharOptions.interface";
import {BehaviorSubject} from "rxjs";
import {Installation} from "../../models/Installations.model";
import {MeterModel} from "../../models/Meter.model";
import {PeriodType} from "../../models/PeriodType.enum";
import {ApexAxisChartSeries, ApexNonAxisChartSeries} from 'ng-apexcharts';

@Component({
  selector: 'app-overview-chart',
  templateUrl: './overview-chart.component.html',
  styleUrls: ['./overview-chart.component.scss']
})

export class OverviewChartComponent implements OnChanges {
  @Input() showLegend = true;
  @Input() sourceType = "installation"
  @Input() source: BehaviorSubject<Installation|MeterModel>;
  sourceID = 0;
  @Input() interval= "";
  @Input() intervalLength: PeriodType = PeriodType.DAY;

  installationOverviewChartOptions : Partial<ChartOptions>;
  meterOverviewChartOptions : Partial<ChartOptions>;
  metersOverviewChartOptions = new BehaviorSubject<Partial<ChartOptions>[]>([]);

  constructor(
    private chartService: ChartService
  ) {
    this.installationOverviewChartOptions = {
      legend:{
        show:false
      },
      chart: {
        height: 350,
        type: 'area',
        zoom: {
          enabled: false
        }
      },
      colors: themeColors,
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      series: new BehaviorSubject<ApexAxisChartSeries | ApexNonAxisChartSeries>([]),
      xaxis: {
        type: 'datetime',
        categories: [],
      }
    }
    this.meterOverviewChartOptions = {series: new BehaviorSubject<ApexAxisChartSeries | ApexNonAxisChartSeries>([])};
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.source || changes.interval || changes.intervalLength) && this.source) {
      if(changes.interval){
        switch (this.intervalLength){
          case PeriodType.DAY:
            this.installationOverviewChartOptions.xaxis = {
                type: 'datetime',
                categories: [
                  "2019-09-18T02:00:00",
                  "2019-09-18T03:00:00",
                  "2019-09-18T04:00:00",
                  "2019-09-18T05:00:00",
                  "2019-09-18T06:00:00",
                  "2019-09-18T07:00:00",
                  "2019-09-18T08:00:00",
                  "2019-09-18T09:00:00",
                  "2019-09-18T10:00:00",
                  "2019-09-18T11:00:00",
                  "2019-09-18T12:00:00",
                  "2019-09-18T13:00:00",
                  "2019-09-18T14:00:00",
                  "2019-09-18T15:00:00",
                  "2019-09-18T16:00:00",
                  "2019-09-18T17:00:00",
                  "2019-09-18T18:00:00",
                  "2019-09-18T19:00:00",
                  "2019-09-18T20:00:00",
                  "2019-09-18T21:00:00",
                  "2019-09-18T22:00:00",
                  "2019-09-18T23:00:00",
                  "2019-09-18T24:00:00",
                  "2019-09-19T01:00:00"
                ]
              };
            this.installationOverviewChartOptions.tooltip = {x: {format: 'HH:mm'}};
            break;
          case PeriodType.MONTH:
            this.installationOverviewChartOptions.xaxis={
              type: 'datetime',
              categories: []
            };
            const [year, month] = this.interval.split('-').map(Number);
            const daysInMonth = new Date(year, month, 0).getDate();
            for (let day = 1; day <= daysInMonth; day++) {
              const date = new Date(year, month - 1, day);
              const yyyy = date.getFullYear();
              const mm = String(date.getMonth() + 1).padStart(2, '0');
              const dd = String(date.getDate()).padStart(2, '0');
              this.installationOverviewChartOptions.xaxis.categories.push(`${yyyy}-${mm}-${dd}`);
            }
            this.installationOverviewChartOptions.tooltip = {x: {format: 'dd.MM.yyyy'}};
            break;
          case PeriodType.YEAR:
            this.installationOverviewChartOptions.xaxis = {
              type: 'category',
              categories: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
            }
            break;
        }
      }
      this.installationOverviewChartOptions.series.next([]);
      this.meterOverviewChartOptions.series.next([]);
      this.metersOverviewChartOptions.next([]);
      this.source.subscribe(source => {
        this.sourceID = source.id;
        if(this.sourceType=="installation") {
          this.chartService.getInstallationOverviewChartData(source.id, this.intervalLength, this.interval).subscribe(data => {
            this.installationOverviewChartOptions.series.next(data);
          });
        } else if(this.sourceType=="meters") {
          this.chartService.getMetersOverviewChartData(source.id, this.intervalLength, this.interval).subscribe(data=>{
            const array = [];
            data.meters.forEach(value=>{
              const chart: Partial<ChartOptions> = {
                chart: {
                  height: 350,
                  type: 'line',
                  zoom: {
                    enabled: false
                  },
                  id: value.id.toString(),
                  group: 'meters'
                },
                title: {
                  text: value.name,
                  align: 'left'
                },
                series: new BehaviorSubject<ApexAxisChartSeries | ApexNonAxisChartSeries>([{
                  name: "Consumption",
                  data: value.values
                }])
              }
              array.push(chart);
            });
            this.metersOverviewChartOptions.next(array)
          });
        } else if(this.sourceType=="meter") {
          this.chartService.getMeterOverviewChartData(source.id, this.intervalLength, this.interval).subscribe(data=>{
            this.meterOverviewChartOptions.series.next(data);
          });
        } else {
          console.error("Invalid Source provided, choose 'installation'|'meters'|'meter'");
        }
      });
    }
  }
}
