import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";

import * as moment from 'moment';
import {Forecast} from "../models/forecast.model";
import {InstallationService} from './installation.service';
import {Installation} from '../models/Installations.model';

@Injectable({
  providedIn: "root"
})
export class PVForecastService {

  constructor(
    private http: HttpClient,
    private installationService: InstallationService
  ) {}

  getTodaysProductionForecast(lon: number, lat: number): Observable<Forecast> {
    const formatedDate: string = moment(Date.now()).format('YYYY-MM-DD');
    return this.http.get<Forecast>(environment.apiHost + '/api/forecast/' + formatedDate + '/' + lon + '/' + lat);
  }
  getProductionForecastForDay(date: string, lon: number, lat: number): Observable<Forecast> {
    return this.http.get<Forecast>(environment.apiHost + '/api/forecast/' + date + '/' + lon + '/' + lat);
  }

  productionFromIllumination(illumination: number): number {
    const installation:Installation = this.installationService.selectedInstallation.value;
    const pvArea = installation.panelArea*installation.panelQuantity/1000000; // mm2 -> m2
    const pvEfficiency = installation.panelEfficiency/10000; // 233 -> 23.3 % -> 0.233
    return +(illumination * pvArea * pvEfficiency).toFixed(2);
  }
}
