<div class="powerflow-chart-box d-flex justify-content-center">
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 368.5 226.8">
    <style type="text/css">
      @keyframes moveArrowLeft {
        0% {transform: translateX(20px); opacity: 0; }
        50% { opacity: 1; }
        100% { transform: translateX(-20px); opacity: 0; }
      }
      @keyframes moveArrowRight {
        0% {transform: translateX(-20px); opacity: 0; }
        50% { opacity: 1; }
        100% { transform: translateX(20px); opacity: 0; }
      }
      @keyframes pulse {
        0% { opacity: 1; }
        50% {opacity: 0.2; }
        100% { opacity: 1; }
      }
      .arrow-animation-l { animation: moveArrowLeft 2s infinite; }
      .arrow-animation-r { animation: moveArrowRight 2s infinite; }
      .pulse-animation { animation: pulse 2s infinite; }
      .pve-g {fill: url(#GREEN_PVE);}
      .home {fill: url(#ORANGE_HOME);}
      .grid-r {fill: url(#RED_GRID);}
      .grid-g {fill: url(#GREEN_GRID);}
      .battery-o {fill: url(#ORANGE_BATTERY);}
      .battery-g {fill: url(#GREEN_BATTERY);}
      .st2 {fill: #FFFFFF8E;}
      .st3 {fill: #FFFFFF;}
      .st5 {font-size: 14.3002px;}
      .st6 {font-size: 10.4496px;}
    </style>
    <linearGradient id="GREEN_PVE" data-name="Nepojmenovaný přechod 48" x1="64.9" y1="60.5" x2="165.6" y2="60.5" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#00ff00"/>
      <stop offset=".2" stop-color="#00ff00" stop-opacity="1"/>
      <stop offset=".3" stop-color="#00ff00" stop-opacity=".9"/>
      <stop offset=".4" stop-color="#00ff00" stop-opacity=".9"/>
      <stop offset=".5" stop-color="#00ff00" stop-opacity=".8"/>
      <stop offset=".6" stop-color="#00ff00" stop-opacity=".7"/>
      <stop offset=".7" stop-color="#00ff00" stop-opacity=".5"/>
      <stop offset=".8" stop-color="#00ff00" stop-opacity=".3"/>
      <stop offset=".9" stop-color="#00ff00" stop-opacity=".1"/>
      <stop offset="1" stop-color="#00ff00" stop-opacity="0"/>
      <stop offset="1" stop-color="#00ff00"/>
    </linearGradient>
    <linearGradient id="ORANGE_HOME" data-name="Nepojmenovaný přechod 47" x1="203.7" y1="60.4" x2="304.2" y2="60.4" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#ff7f00"/>
      <stop offset="0" stop-color="#ff7f00" stop-opacity="0"/>
      <stop offset="0" stop-color="#ff7f00" stop-opacity=".1"/>
      <stop offset=".2" stop-color="#ff7f00" stop-opacity=".3"/>
      <stop offset=".3" stop-color="#ff7f00" stop-opacity=".5"/>
      <stop offset=".4" stop-color="#ff7f00" stop-opacity=".7"/>
      <stop offset=".5" stop-color="#ff7f00" stop-opacity=".8"/>
      <stop offset=".6" stop-color="#ff7f00" stop-opacity=".9"/>
      <stop offset=".7" stop-color="#ff7f00" stop-opacity=".9"/>
      <stop offset=".8" stop-color="#ff7f00" stop-opacity="1"/>
      <stop offset="1" stop-color="#ff7f00"/>
    </linearGradient>
    <linearGradient id="RED_GRID" data-name="Nepojmenovaný přechod 46" x1="55.3" y1="169.4" x2="165" y2="169.4" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#ff0000"/>
      <stop offset=".2" stop-color="#ff0000" stop-opacity="1"/>
      <stop offset=".3" stop-color="#ff0000" stop-opacity=".9"/>
      <stop offset=".4" stop-color="#ff0000" stop-opacity=".9"/>
      <stop offset=".5" stop-color="#ff0000" stop-opacity=".8"/>
      <stop offset=".6" stop-color="#ff0000" stop-opacity=".7"/>
      <stop offset=".7" stop-color="#ff0000" stop-opacity=".5"/>
      <stop offset=".8" stop-color="#ff0000" stop-opacity=".3"/>
      <stop offset=".9" stop-color="#ff0000" stop-opacity=".1"/>
      <stop offset="1" stop-color="#ff0000" stop-opacity="0"/>
      <stop offset="1" stop-color="#ff0000"/>
    </linearGradient>
    <linearGradient id="GREEN_GRID" data-name="Nepojmenovaný přechod 46" x1="55.3" y1="169.4" x2="165" y2="169.4" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#00ff00"/>
      <stop offset=".2" stop-color="#00ff00" stop-opacity="1"/>
      <stop offset=".3" stop-color="#00ff00" stop-opacity=".9"/>
      <stop offset=".4" stop-color="#00ff00" stop-opacity=".9"/>
      <stop offset=".5" stop-color="#00ff00" stop-opacity=".8"/>
      <stop offset=".6" stop-color="#00ff00" stop-opacity=".7"/>
      <stop offset=".7" stop-color="#00ff00" stop-opacity=".5"/>
      <stop offset=".8" stop-color="#00ff00" stop-opacity=".3"/>
      <stop offset=".9" stop-color="#00ff00" stop-opacity=".1"/>
      <stop offset="1" stop-color="#00ff00" stop-opacity="0"/>
      <stop offset="1" stop-color="#00ff00"/>
    </linearGradient>
    <linearGradient id="ORANGE_BATTERY" data-name="Nepojmenovaný přechod 45" x1="203.2" y1="169.5" x2="304" y2="169.5" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#ff7f00"/>
      <stop offset="0" stop-color="#ff7f00" stop-opacity="0"/>
      <stop offset="0" stop-color="#ff7f00" stop-opacity=".1"/>
      <stop offset=".2" stop-color="#ff7f00" stop-opacity=".3"/>
      <stop offset=".3" stop-color="#ff7f00" stop-opacity=".5"/>
      <stop offset=".4" stop-color="#ff7f00" stop-opacity=".7"/>
      <stop offset=".5" stop-color="#ff7f00" stop-opacity=".8"/>
      <stop offset=".6" stop-color="#ff7f00" stop-opacity=".9"/>
      <stop offset=".7" stop-color="#ff7f00" stop-opacity=".9"/>
      <stop offset=".8" stop-color="#ff7f00" stop-opacity="1"/>
      <stop offset="1" stop-color="#ff7f00"/>
    </linearGradient>
    <linearGradient id="GREEN_BATTERY" data-name="Nepojmenovaný přechod 45" x1="203.2" y1="169.5" x2="304" y2="169.5" gradientUnits="userSpaceOnUse">
      <stop offset="0" stop-color="#00ff00"/>
      <stop offset="0" stop-color="#00ff00" stop-opacity="0"/>
      <stop offset="0" stop-color="#00ff00" stop-opacity=".1"/>
      <stop offset=".2" stop-color="#00ff00" stop-opacity=".3"/>
      <stop offset=".3" stop-color="#00ff00" stop-opacity=".5"/>
      <stop offset=".4" stop-color="#00ff00" stop-opacity=".7"/>
      <stop offset=".5" stop-color="#00ff00" stop-opacity=".8"/>
      <stop offset=".6" stop-color="#00ff00" stop-opacity=".9"/>
      <stop offset=".7" stop-color="#00ff00" stop-opacity=".9"/>
      <stop offset=".8" stop-color="#00ff00" stop-opacity="1"/>
      <stop offset="1" stop-color="#00ff00"/>
    </linearGradient>
    <g id="Paths">
	    <g id="Pahs">
		    <path id="path" class="st2" d="M301.5,167.1h-98c-8.6,0-15.6-6.5-16.6-14.8c0.1-0.7,0.1-1.4,0.1-2.1v-30.3c0,0,0,0,0,0v-4.8 c0-0.1,0-0.2,0-0.4v-35c0,0,0-0.1,0-0.1c0-9.3,7.5-16.8,16.8-16.8h98c1.3,0,2.4-1.1,2.4-2.4s-1.1-2.4-2.4-2.4h-98 c-8.4,0-15.6,4.8-19.2,11.8c-3.6-6.9-10.8-11.7-19.2-11.7H67.3c-1.3,0-2.4,1.1-2.4,2.4v0c0,1.3,1.1,2.4,2.4,2.4h98.2 c8.3,0,15.2,6,16.5,13.9v38.5V129v21.3c0,0.7,0,1.3,0.1,1.9c-1,8.3-8.1,14.8-16.7,14.8H57.7c-1.3,0-2.4,1.1-2.4,2.4 s1.1,2.4,2.4,2.4h107.7c8.3,0,15.4-4.6,19.1-11.5c3.6,6.9,10.8,11.6,19.1,11.6h98c1.3,0,2.4-1.1,2.4-2.4S302.8,167.1,301.5,167.1z"/>
        <g id="Gradients">
          <path class="{{(currentProductionData|async).pv>0?'pve-g':''}} {{(currentProductionData|async).pv===0?'d-none':'pulse-animation'}}" d="M67.3,62.9h98.2s0,0,.1,0c0,0,0,0,0,0,0-1.6,0-3.2,0-4.8,0,0-.1,0-.2,0h-98.1c-1.3,0-2.4,1.1-2.4,2.4s1.1,2.4,2.4,2.4Z"/>
          <path class="{{(currentProductionData|async).consumption !== 0?'home pulse-animation':'d-none'}}" d="M203.7,62.8s0,0,.1,0h98c1.3,0,2.4-1.1,2.4-2.4s-1.1-2.4-2.4-2.4h-98s0,0,0,0c0,1.6,0,3.2,0,4.8Z"/>
          <path class="{{(currentProductionData|async).grid>0?'grid-r':''}}{{(currentProductionData|async).grid<0?'grid-g':''}} {{(currentProductionData|async).grid===0?'d-none':'pulse-animation'}}" d="M165,167H57.7c-1.3,0-2.4,1.1-2.4,2.4s1.1,2.4,2.4,2.4h107.2c0-1.6,0-3.2,0-4.8Z"/>
          <path class="{{(currentProductionData|async).battery>0?'battery-o':''}}{{(currentProductionData|async).battery<0?'battery-g':''}} {{(currentProductionData|async).battery===0?'d-none':'pulse-animation'}}" d="M301.5,167.1h-98c0,0-.2,0-.3,0,0,1.6,0,3.2,0,4.8.1,0,.3,0,.4,0h98c1.3,0,2.4-1.1,2.4-2.4s-1.2-2.4-2.5-2.4Z"/>
          </g>
	    </g>
      <g id="Arrows">
		    <path *ngIf="(currentProductionData|async).grid>0" id="grid-out" class="st3 arrow-animation-r" d="M114.8,176.9l6.6-6.6c0.7-0.7,0.7-1.7,0-2.4l-6.6-6.6c-0.7-0.7-1.8-0.2-1.8,0.8v14.2 C113,177.1,114.1,177.6,114.8,176.9z"/>
        <path *ngIf="(currentProductionData|async).grid<0" id="grid-in" class="st3 arrow-animation-l" d="M120.1,161.2l-6.6,6.6c-0.7,0.7-0.7,1.7,0,2.4l6.6,6.6c0.7,0.7,1.8,0.2,1.8-0.8l0-14.2 C122,161,120.8,160.5,120.1,161.2z"/>
        <path *ngIf="(currentProductionData|async).pv>0" id="solar-out" class="st3 arrow-animation-r" d="M114.6,68.5l6.6-6.6c0.7-0.7,0.7-1.7,0-2.4l-6.6-6.6c-0.7-0.7-1.8-0.2-1.8,0.8v14.2 C112.8,68.7,113.9,69.2,114.6,68.5z"/>
        <path *ngIf="(currentProductionData|async).battery<0" id="battery-out" class="st3 arrow-animation-r" d="M247.8,176.9l6.6-6.6c0.7-0.7,0.7-1.7,0-2.4l-6.6-6.6c-0.7-0.7-1.8-0.2-1.8,0.8v14.2 C246,177.1,247.1,177.6,247.8,176.9z"/>
        <path *ngIf="(currentProductionData|async).battery>0" id="battery-in" class="st3 arrow-animation-l" d="M253.1,161.2l-6.6,6.6c-0.7,0.7-0.7,1.7,0,2.4l6.6,6.6c0.7,0.7,1.8,0.2,1.8-0.8V162 C255,161,253.8,160.5,253.1,161.2z"/>
        <path *ngIf="(currentProductionData|async).consumption !== 0" id="house-out" class="st3 arrow-animation-r" d="M247.6,68.5l6.6-6.6c0.7-0.7,0.7-1.7,0-2.4l-6.6-6.6c-0.7-0.7-1.8-0.2-1.8,0.8v14.2 C245.8,68.7,246.9,69.2,247.6,68.5z"/>
      </g>
    </g>
    <g id="Icons">
	    <path id="Solar_x5F_icon" class="st3" d="M19.3,44c-2.5,0-4.6,1.7-5.1,4.2l-4.1,20.7c-0.6,3.2,1.8,6.2,5.1,6.2h17.6v5.2h-5.2 c-1.4,0-2.6,1.2-2.6,2.6s1.2,2.6,2.6,2.6h15.5c1.4,0,2.6-1.2,2.6-2.6s-1.2-2.6-2.6-2.6h-5.2v-5.2h17.6c3.3,0,5.7-3,5.1-6.2 l-4.1-20.7c-0.5-2.4-2.6-4.2-5.1-4.2H19.3z M30.5,49.2h9.6l0.8,8.4H29.7L30.5,49.2z M25.8,57.6h-8.2l1.7-8.4h7.3L25.8,57.6z M16.9,61.5h8.6l-0.8,8.4h-9.4L16.9,61.5z M29.3,61.5h12l0.8,8.4H28.5L29.3,61.5L29.3,61.5z M45.2,61.5h8.6l1.7,8.4h-9.4L45.2,61.5z M53,57.6h-8.2L44,49.2h7.3l2.5-0.5l-2.5,0.5L53,57.6L53,57.6z"/>
      <path id="Grid_x5F_icon" class="st3" d="M45.9,151.3c0.5-1.1,0.1-2.4-0.9-3.2c-1-0.7-2.4-0.7-3.3,0.1l-21.1,18.5c-0.8,0.7-1.1,1.9-0.7,2.9c0.4,1,1.4,1.7,2.5,1.7h9.2l-6.3,14.8c-0.5,1.1-0.1,2.4,0.9,3.2c1,0.7,2.4,0.7,3.3-0.1l21.1-18.5c0.8-0.7,1.1-1.9,0.7-2.9s-1.4-1.7-2.5-1.7h-9.2L45.9,151.3z"/>
      <g id="Battery_x5F_icon">
		    <path id="Battery_x5F_battery" class="st3" d="M356.2,163v-1.4c0-3.9-3.1-7-7-7h-33.8c-3.9,0-7,3.1-7,7v14.1c0,3.9,3.1,7,7,7h33.8 c3.9,0,7-3.1,7-7v-1.4c1.6,0,2.8-1.3,2.8-2.8v-5.6C359,164.3,357.7,163,356.2,163z M350.6,175.7c0,0.8-0.6,1.4-1.4,1.4h-33.8 c-0.8,0-1.4-0.6-1.4-1.4v-14.1c0-0.8,0.6-1.4,1.4-1.4h33.8c0.8,0,1.4,0.6,1.4,1.4V175.7z"/>
        <rect *ngIf="(currentProductionData|async).batterySoc>=90" id="Battery_x5F_value_x5F_4" x="316.8" y="163" class="st2" width="31" height="11.3"/>
        <rect *ngIf="(currentProductionData|async).batterySoc>=60 && (currentProductionData|async).batterySoc<90" id="Battery_x5F_value_x5F_3" x="316.8" y="163" class="st2" width="23.3" height="11.3"/>
        <rect *ngIf="(currentProductionData|async).batterySoc>=40 && (currentProductionData|async).batterySoc<60" id="Battery_x5F_value_x5F_2" x="316.8" y="163" class="st2" width="15.5" height="11.3"/>
        <rect *ngIf="(currentProductionData|async).batterySoc>=20 && (currentProductionData|async).batterySoc<40" id="Battery_x5F_value_x5F_1" x="316.8" y="163" class="st2" width="7.8" height="11.3"/>
	    </g>
      <g id="House_x5F_icon_1_">
		    <path id="House_x5F_door" class="st2" d="M337.3,78.9v-1.8v-4.9c0-1.4-0.8-2.5-1.8-2.5H332c-1,0-1.8,1.1-1.8,2.5v4.9v1.8 c0,1.4-0.7,2.6-1.6,2.9h10.3C338,81.5,337.3,80.3,337.3,78.9z"/>
        <path id="House_x5F_house_2_" class="st3" d="M358.9,59.4c0,1.6-1.3,2.8-2.8,2.8h-2.8l0.1,14.1c0,0.2,0,0.5,0,0.7v1.4 c0,1.9-1.6,3.5-3.5,3.5h-1.4c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.4,0h-2.9h-2.1c-1.9,0-3.5-1.6-3.5-3.5v-2.1v-5.6 c0-1.6-1.3-2.8-2.8-2.8h-5.6c-1.6,0-2.8,1.3-2.8,2.8v5.6v2.1c0,1.9-1.6,3.5-3.5,3.5h-2.1h-2.8c-0.1,0-0.3,0-0.4,0 c-0.1,0-0.2,0-0.3,0h-1.4c-1.9,0-3.5-1.6-3.5-3.5v-9.9c0-0.1,0-0.2,0-0.2v-6.1h-2.8c-1.6,0-2.8-1.2-2.8-2.8c0-0.8,0.3-1.5,0.9-2.1 l22.6-19.7c0.6-0.6,1.3-0.7,1.9-0.7c0.6,0,1.3,0.2,1.8,0.6L358,57.3C358.7,57.9,359,58.6,358.9,59.4z"/>
	    </g>
    </g>
    <g id="Values">
      <text id="Solar_x5F_Value_1_" transform="matrix(1 0 0 1 86.9126 48.5342)" class="st3 st4 st5">{{(currentProductionData|async).pv | powerConversion}}</text>
      <text id="Grid_x5F_value_1_" transform="matrix(1 0 0 1 102.4951 156.8789)" class="st3 st4 st5">{{(currentProductionData|async).grid | abs | powerConversion}}</text>
      <text id="Battery_x5F_value_1_" transform="matrix(1 0 0 1 219.1504 157.2607)" class="st3 st4 st5">{{(currentProductionData|async).battery | abs | powerConversion}}</text>
      <text id="House_x5F_value_1_" transform="matrix(1 0 0 1 219.0308 48.7314)" class="st3 st4 st5">{{(currentProductionData|async).consumption | powerConversion}}</text>
      <text id="Battery_x5F_percentage_2_" transform="matrix(1 0 0 1 322.5986 172.4248)" class="st3 st4 st6">{{(currentProductionData|async).batterySoc}}%</text>
    </g>
  </svg>
</div>

