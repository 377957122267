import {Installation} from './Installations.model';

export class UserModel {
  id = '';
  created = '';
  name = '';
  email = '';
  phone = '';
  role = UserRole.ROLE_USER;
  password = '';
  installations: Installation[] = [];
}

export enum UserRole {
  ROLE_USER="ROLE_USER",
  ROLE_STAFF="ROLE_STAFF",
  ROLE_TECHNICIAN="ROLE_TECHNICIAN",
  ROLE_ADMIN="ROLE_ADMIN"
}
