import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'powerConversion'
})
export class PowerConversionPipe implements PipeTransform {
  transform(value: number): string {
    if (value >= 1e6) {
      return (value / 1e6).toFixed(2) + ' MW';
    } else if (value >= 1e3) {
      return (value / 1e3).toFixed(2) + ' kW';
    } else {
      return value + ' W';
    }
  }
}
