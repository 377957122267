import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject, Observable} from 'rxjs';
import {Installation} from "../models/Installations.model";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: "root"
})
export class InstallationService {

  selectedInstallation: BehaviorSubject<Installation> = new BehaviorSubject<Installation>(null);

  constructor(
    private http: HttpClient,
  ) {}

  getInstallationById(id: number): Observable<Installation> {
    return this.http.get<Installation>(environment.apiHost + '/api/installation/' + id);
  }
  getAll(): Observable<Installation[]> {
    return this.http.get<Installation[]>(environment.apiHost + '/api/installation/getall');
  }
  createInstallation(i: Installation): Observable<number> {
    return this.http.post<number>(environment.apiHost + '/api/installation/create', i);
  }
  updateInstallation(i: Installation): Observable<number> {
    return this.http.post<number>(environment.apiHost + '/api/installation/update', i);
  }
  deleteInstallation(id: string): Observable<unknown> {
    return this.http.delete<unknown>(environment.apiHost + '/api/installation/' + id);
  }
}
