<ng-container *ngIf="(dataExists | async) else fetching">
<h5 class="text-center text-lg-left my-2"><i class="ft-calendar ml-2"></i> Choose interval</h5>
<div class="row mt-3">
  <div class="col-12 col-lg-5">
    <div class="row">
      <div class="col-sm-4">
        <div class="input-group">
        <select
          class="form-control"
          [(ngModel)]="intervalLength"
          (ngModelChange)="parseInterval()">
          <option *ngFor="let interval of PeriodType | keyvalue" [value]="interval.key">
            {{ interval.value }}
          </option>
        </select>
        </div>
      </div>
      <div class="col-sm-8">
        <div class="input-group">
          <select
            *ngIf="intervalLength===PeriodType.DAY"
            class="form-control"
            [(ngModel)]="intervalSelected.day"
            (ngModelChange)="parseInterval()">
            <option *ngFor="let day of days">{{day}}</option>
          </select>
          <select
            *ngIf="intervalLength!==PeriodType.YEAR"
            class="form-control"
            [(ngModel)]="intervalSelected.month"
            (ngModelChange)="parseInterval()">
            <option *ngFor="let month of months" >{{ month }}</option>
          </select>
          <select
            id="yearIntervalInput"
            class="form-control"
            [(ngModel)]="intervalSelected.year"
            (ngModelChange)="parseInterval()">
            <option *ngFor="let year of years">{{year}}</option>
          </select>
          <div class="input-group-append">
            <div class="input-group-text">
              <i class="fa fa-calendar"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg text-lg-right text-center mb-sm-0 mb-2">
    <button *ngIf="activeTab===1" class="mx-2 btn btn-success" (click)="exportToExcel()"><i class="ft-clipboard"></i> To Excel</button>
<!--    TODO-->
<!--    <button class="mx-2 btn btn-danger"><i class="ft-file-text"></i> To PDF</button>-->
  </div>
</div>
<ul ngbNav #view="ngbNav" [activeId]=activeTab (navChange)="onNavChange($event)" class="nav-tabs justify-content-sm-start justify-content-center">
  <li [ngbNavItem]=1>
    <a ngbNavLink class="nav-link d-flex align-items-center">
      <i class="ft-align-justify mr-1"></i><span class="d-none d-sm-block">Table</span>
    </a>
    <ng-template ngbNavContent>
      <app-installation-report-table [interval]="intervalString" [intervalLength]="intervalLength" [tableData]="tableData"></app-installation-report-table>
    </ng-template>
  </li>
  <li [ngbNavItem]=2>
    <a ngbNavLink class="nav-link d-flex align-items-center">
      <i class="ft-bar-chart-2 mr-1"></i><span class="d-none d-sm-block">Graphs</span>
    </a>
    <ng-template ngbNavContent>
      <h4>Installation overwiev</h4>
      <div class="row flex-column-reverse flex-lg-row">
        <div class="col-xl-8 col-lg-7 col-12" [ngClass]="{'col-8':(donutChartOptions.series|async).length}">
          <app-overview-chart [sourceType]="'installation'" [source]="installation" [interval]="intervalString" [intervalLength]="intervalLength"></app-overview-chart>
        </div>
        <div class="col-xl-4 col-lg-5 col-12" *ngIf="(donutChartOptions.series|async).length">
          <div class="card-content">
            <div class="card-body">
              <div class="chart-info mb-lg-3 ml-lg-3 text-center text-lg-left">
                <span class="text-nowrap">
                  <span class="d-inline-block rounded-circle mr-1" style="width:15px; height:15px; border: 2px solid white"></span>
                  <span>Consumption by meter</span>
                </span>
              </div>
              <apx-chart
                [series]="(donutChartOptions.series|async)"
                [colors]="donutChartOptions.colors"
                [chart]="donutChartOptions.chart"
                [xaxis]="donutChartOptions.xaxis"
                [yaxis]="donutChartOptions.yaxis"
                [title]="donutChartOptions.title"
                [dataLabels]="donutChartOptions.dataLabels"
                [stroke]="donutChartOptions.stroke"
                [grid]="donutChartOptions.grid"
                [tooltip]="donutChartOptions.tooltip"
                [plotOptions]="donutChartOptions.plotOptions"
                [fill]="donutChartOptions.fill"
                [legend]="donutChartOptions.legend"
                [labels]="(donutChartOptions.labels|async)"
                [markers]="donutChartOptions.markers"
                [theme]="donutChartOptions.theme"
                [responsive]="donutChartOptions.responsive"
              ></apx-chart>
            </div>
          </div>
        </div>
      </div>
      <h4>Individual Meters</h4>
      <app-overview-chart [sourceType]="'meters'" [source]="installation" [interval]="intervalString" [intervalLength]="intervalLength"></app-overview-chart>
    </ng-template>
  </li>
</ul>
<div class="tab-content">
  <div [ngbNavOutlet]="view" class="mt-2"></div>
</div>
</ng-container>

<ng-template #fetching>
  <p class="ml-2" *ngIf="(fetchingData | async) else fetching_error">
    Fetching data...
  </p>
  <ng-template #fetching_error class="ml-3">
    <div class="card-body">
      <h5>Module error...</h5>
      <p>Couldn't fetch data!</p>
    </div>
  </ng-template>
</ng-template>

