import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {PeriodType} from "../models/PeriodType.enum";
import {InstallationOverviewTable} from '../models/InstallationOverviewTable.interface';
import {InstallationOverviewMeters} from '../models/InstallationOverviewMeters.interface';
import {ApexAxisChartSeries} from 'ng-apexcharts';

@Injectable({
  providedIn: "root"
})
export class ChartService {

  constructor(
    private http: HttpClient,
  ) {}

  getProductionChartDataForDate(installationId: number, date: string) {
    return this.http.get<{ name:string, data:number[] }>(environment.apiHost + '/api/chart/production/' + installationId + '/' + date);
  }

  getInstallationOverviewChartData(installationId: number, periodType: PeriodType, period: string) {
    return this.http.get<ApexAxisChartSeries>(environment.apiHost + '/api/chart/overview/installation/'+installationId+'/'+periodType+'/'+period);
  }

  getMetersOverviewChartData(installationId: number, periodType: PeriodType, period: string) {
    return this.http.get<InstallationOverviewMeters>(environment.apiHost + '/api/chart/overview/meters/chart/'+installationId+'/'+periodType+'/'+period);
  }

  getMeterOverviewChartData(meterId: number, periodType: PeriodType, period: string) {
    return this.http.get<ApexAxisChartSeries >(environment.apiHost + '/api/chart/overview/meter/chart/'+meterId+'/'+periodType+'/'+period);
  }

  getMetersOverviewTableData(installationId: number, periodType: PeriodType, period: string) {
    return this.http.get<InstallationOverviewTable>(environment.apiHost + '/api/chart/overview/meters/table/'+installationId+'/'+periodType+'/'+period);
  }
}
