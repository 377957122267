import {Component, Input} from '@angular/core';
import {CurrentProductionDataModel} from "../../models/CurrentProductionData.model";
import {BehaviorSubject} from "rxjs";

@Component({
  selector: 'app-powerflow-diagram',
  templateUrl: './powerflow-diagram.component.html',
  styleUrls: ['./powerflow-diagram.component.scss']
})

export class PowerflowDiagramComponent {
  @Input() currentProductionData = new BehaviorSubject<CurrentProductionDataModel>(new CurrentProductionDataModel());
}
