import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ZDT'
})
export class ZonedDateTimePipe implements PipeTransform {
  transform(zdt: string, enableTime = true): string {
    const year = zdt.substring(0,4);
    const month = zdt.substring(5,7);
    const day = zdt.substring(8,10);
    const hours = zdt.substring(11,13);
    const minutes = zdt.substring(14,16);
    const secounds = zdt.substring(17,19);
    if(enableTime) {
      return day + '.' + month + '.' + year + ' ' + hours + ':' + minutes + ':' + secounds;
    } else {
      return day + '.' + month + '.' + year;
    }
  }
}
