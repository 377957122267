import {Component, OnInit} from '@angular/core';
import {PVForecastService} from "../../services/PVforecast.service";
import * as moment from "moment/moment";
import {BehaviorSubject} from "rxjs";
import {InstallationService} from '../../services/installation.service';
import {Installation} from '../../models/Installations.model';
@Component({
  selector: 'app-forecast-module',
  templateUrl: './forecast-module.component.html',
  styleUrls: ['./forecast-module.component.scss']
})

export class ForecastModuleComponent implements OnInit {
  day = new Date().getDay();
  maxims = new BehaviorSubject<number[]>([]);
  currentIllumination = new BehaviorSubject<number>(0);
  constructor(
    private pvForecastService: PVForecastService,
    private installationService: InstallationService
  ) {
  }
  ngOnInit(): void {
    this.installationService.selectedInstallation.subscribe(installation=> {
      if(installation) {
        this.setUpValue(installation);
      }
    });
  }

  productionFromIllumination(illumination: number): number {
    return  this.pvForecastService.productionFromIllumination(illumination);
  }
  setUpValue(installation:Installation) {
    for(let i = 0; i < 3; i++){
      const date = new Date();
      date.setDate(date.getDate() + i);
      const formatedDate: string = moment(date).format('YYYY-MM-DD')
      this.pvForecastService.getProductionForecastForDay(formatedDate,installation.geoLng,installation.geoLat).subscribe((data) => {
        if(data){
          if(i==0) {
            this.currentIllumination.next(data.values[new Date().getHours()]);
          }
          const values = data.values;

          const currentMaxims = this.maxims.getValue();
          currentMaxims[i] = Math.max(...values);
          this.maxims.next(currentMaxims);
        }
      });
    }
  }

  getDay(day: number): string {
    if(day > 6) day = day - 7;
    switch (day) {
      case 0:
        return 'Sunday';
      case 1:
        return 'Monday';
      case 2:
        return 'Tuesday';
      case 3:
        return 'Wednesday';
      case 4:
        return 'Thursday';
      case 5:
        return 'Friday';
      case 6:
        return 'Saturday';
      default:
        return '';
    }
  }
}
