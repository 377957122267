import { RouteInfo } from '../vertical-menu/vertical-menu.metadata';
import {UserRole} from '../models/User.model';

export const HROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Dashboard', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], role: UserRole.ROLE_USER},
  { path: '/forecast', title: 'Forecast', icon: 'ft-sun', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], role: UserRole.ROLE_USER},
  // { path: '/reports', title: 'Reports', icon: 'ft-bar-chart-2', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], role: UserRole.ROLE_USER},
  { path: '/installation/view', title: 'Installation', icon: 'ft-hard-drive', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], role: UserRole.ROLE_USER},
  // { path: '/manage', title: 'Management', icon: 'ft-clipboard', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], role: UserRole.USER},
  // { path: '/settings', title: 'Settings', icon: 'ft-settings', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], role: UserRole.USER},
  {
    path: '', title: 'Administration', icon: 'ft-user-check', class: 'dropdown nav-item has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
      { path: '/user/list', title: 'Users', icon: 'ft-users', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [], role: UserRole.ROLE_STAFF},
      { path: '/installation', title: 'Installations', icon: 'ft-hard-drive', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [], role: UserRole.ROLE_STAFF},
    ], role: UserRole.ROLE_STAFF
  },
  // {
  //   path: '', title: 'Support', icon: 'ft-life-buoy', class: 'dropdown nav-item has-sub', badge: '', badgeClass: '', isExternalLink: false, submenu: [
  //     { path: '/support/deviceInfo', title: 'About device', icon: 'ft-cpu', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [], role: UserRole.USER},
  //     { path: '/support/faq', title: 'FAQ', icon: 'ft-layers', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [], role: UserRole.USER},
  //     { path: '/support/contact', title: 'Contact us', icon: 'ft-phone', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [], role: UserRole.USER},
  //   ], role: UserRole.USER
  // },
];
