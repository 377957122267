<div *ngIf="sourceType==='installation'" class="row">
  <div class="col">
    <div class="card-content">
      <div class="card-body">
        <div *ngIf="showLegend" class="chart-info mb-lg-3 ml-lg-3 text-center text-lg-left">
          <span class="text-nowrap">
            <span class="bg-primary d-inline-block rounded-circle mr-1" style="width:15px; height:15px;"></span>
            <span>Consumption</span>
          </span>
          <span class="text-nowrap ml-2">
            <span class="bg-success d-inline-block rounded-circle mr-1" style="width:15px; height:15px;"></span>
            <span>From PV</span>
          </span>
          <span class="text-nowrap ml-2">
            <span class="bg-danger d-inline-block rounded-circle mr-1" style="width:15px; height:15px;"></span>
            <span>From Grid</span>
          </span>
          <span class="text-nowrap ml-2">
            <span class="bg-warning d-inline-block rounded-circle mr-1" style="width:15px; height:15px;"></span>
            <span>From Battery</span>
          </span>
        </div>
        <div>
          <apx-chart
            *ngIf="(installationOverviewChartOptions.series|async)?.length > 2 else fetching"
            [series]="(installationOverviewChartOptions.series|async)"
            [colors]="installationOverviewChartOptions.colors"
            [chart]="installationOverviewChartOptions.chart"
            [xaxis]="installationOverviewChartOptions.xaxis"
            [yaxis]="installationOverviewChartOptions.yaxis"
            [title]="installationOverviewChartOptions.title"
            [dataLabels]="installationOverviewChartOptions.dataLabels"
            [stroke]="installationOverviewChartOptions.stroke"
            [grid]="installationOverviewChartOptions.grid"
            [tooltip]="installationOverviewChartOptions.tooltip"
            [plotOptions]="installationOverviewChartOptions.plotOptions"
            [fill]="installationOverviewChartOptions.fill"
            [legend]="installationOverviewChartOptions.legend"
            [labels]="(installationOverviewChartOptions.labels|async)"
            [markers]="installationOverviewChartOptions.markers"
            [theme]="installationOverviewChartOptions.theme"
            [responsive]="installationOverviewChartOptions.responsive"
          ></apx-chart>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="sourceType==='meters'" class="row">
  <div class="col">
    <div class="card-content">
      <div class="card-body">
        <div class="row">
          <div *ngFor="let chart of (metersOverviewChartOptions|async)" class="col-xl-4 col-md-6 col-12">
            <apx-chart
              *ngIf="(chart.series|async)?.length !== 0 else fetching"
              [series]="(chart.series|async)"
              [colors]="installationOverviewChartOptions.colors"
              [chart]="chart.chart"
              [xaxis]="installationOverviewChartOptions.xaxis"
              [yaxis]="installationOverviewChartOptions.yaxis"
              [title]="chart.title"
              [dataLabels]="installationOverviewChartOptions.dataLabels"
              [stroke]="installationOverviewChartOptions.stroke"
              [grid]="installationOverviewChartOptions.grid"
              [tooltip]="installationOverviewChartOptions.tooltip"
              [plotOptions]="installationOverviewChartOptions.plotOptions"
              [fill]="installationOverviewChartOptions.fill"
              [legend]="installationOverviewChartOptions.legend"
              [labels]="(installationOverviewChartOptions.labels|async)"
              [markers]="installationOverviewChartOptions.markers"
              [theme]="installationOverviewChartOptions.theme"
              [responsive]="installationOverviewChartOptions.responsive"
            ></apx-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
</div><div *ngIf="sourceType==='meter'" class="row">
  <div class="col">
    <div class="card-content">
      <div class="card-body">
            <apx-chart
              *ngIf="(meterOverviewChartOptions.series|async)?.length !== 0 else fetching"
              [series]="(meterOverviewChartOptions.series|async)"
              [colors]="installationOverviewChartOptions.colors"
              [chart]="installationOverviewChartOptions.chart"
              [xaxis]="installationOverviewChartOptions.xaxis"
              [yaxis]="installationOverviewChartOptions.yaxis"
              [title]="installationOverviewChartOptions.title"
              [dataLabels]="installationOverviewChartOptions.dataLabels"
              [stroke]="installationOverviewChartOptions.stroke"
              [grid]="installationOverviewChartOptions.grid"
              [tooltip]="installationOverviewChartOptions.tooltip"
              [plotOptions]="installationOverviewChartOptions.plotOptions"
              [fill]="installationOverviewChartOptions.fill"
              [legend]="installationOverviewChartOptions.legend"
              [labels]="(installationOverviewChartOptions.labels|async)"
              [markers]="installationOverviewChartOptions.markers"
              [theme]="installationOverviewChartOptions.theme"
              [responsive]="installationOverviewChartOptions.responsive"
            ></apx-chart>
      </div>
    </div>
  </div>
</div>
<ng-template #fetching>
  <p class="ml-3">Fetching data...</p>
</ng-template>
