<div class="row align-center">
<div class="col-sm-4 col-15 weather-widget">
  <div class="weather-icon row justify-content-center">
    <i class="wi wi-day-sunny"></i>
  </div>
  <h5 class="condition row justify-content-center">Current Illumination</h5>
  <h3 class="location-name row justify-content-center">{{(currentIllumination|async)}} W/m2</h3>
</div>
<div class="col-sm-8 col-15 weather-forecast">

  <ng-container  *ngFor="let maxim of (maxims|async); index as i">
    <div *ngIf="maxim" class="forecast-item">
      <div class="day">
        <h4>{{getDay(day+i)}} <sup *ngIf="i===0">Today</sup></h4>
        <h6>Max Illumination: {{maxim}} W/m2</h6>
      </div>
      <div class="forecast">
        <i class="ft-zap"></i> Expected production
        <h6>{{productionFromIllumination(maxim)}} W</h6>
      </div>
    </div>
  </ng-container >

</div>
</div>
