import {Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Installation} from "../../models/Installations.model";
import {BehaviorSubject} from "rxjs";
import {InstallationReportTableComponent} from "./Installation-report-table/Installation-report-table.component";
import {NgbNavChangeEvent} from "@ng-bootstrap/ng-bootstrap";
import {ActivatedRoute, Router} from "@angular/router";
import {ChartOptions, themeColors} from "../../models/CharOptions.interface";
import {ChartService} from "../../services/chart.service";
import {PeriodType} from "../../models/PeriodType.enum";
import {InstallationOverviewTable} from '../../models/InstallationOverviewTable.interface';

@Component({
  selector: 'app-installation-report-module',
  templateUrl: './installation-report-module.component.html',
  styleUrls: ['./installation-report-module.component.scss']
})

export class InstallationReportModuleComponent implements OnInit, OnChanges {
  @Input() installation: BehaviorSubject<Installation>;
  fetchingData = new BehaviorSubject<boolean>(true);
  dataExists = new BehaviorSubject<boolean>(true);
  installationCreated= "";
  installationID = 0;
  @ViewChild(InstallationReportTableComponent) tableViewComponent: InstallationReportTableComponent;
  activeTab = 1;

  donutChartOptions : Partial<ChartOptions>;
  tableData: BehaviorSubject<InstallationOverviewTable> = new BehaviorSubject<InstallationOverviewTable>(undefined);

  intervalLength: PeriodType = PeriodType.DAY;
  intervalSelected = {
    "year" : new Date().getFullYear(),
    "month" : new Date().getMonth() + 1,
    "day": new Date().getDate(),
  };
  intervalString = this.intervalSelected.year + '-' + String(this.intervalSelected.month).padStart(2, '0') + '-' + String(this.intervalSelected.day).padStart(2, '0');
  years: number[] = [];
  months: number[] = [];
  days: number[] = [];


  constructor(
    private chartService: ChartService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.donutChartOptions = {
      chart: {
        type: 'donut',
        height: 320
      },
      colors: themeColors,
      series: new BehaviorSubject<number[]>([]),
      labels: new BehaviorSubject<string[]>([]),
      legend: {
        show:false,
      },
      responsive: [{
        breakpoint: 576,
        options: {
          chart: {
            width: 300
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    }
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe(queryParamMap => {
      if(queryParamMap.get('view')) {
        this.activeTab = +queryParamMap.get('view');
      } else {
        if(queryParamMap.get('tab')) {
          this.router.navigate([], {
            relativeTo: this.route, queryParams: {
              tab: +queryParamMap.get('tab'),
              view: 1
            }
          });
        } else {
          this.router.navigate([], {
            relativeTo: this.route, queryParams: {
              view: 1
            }
          });
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.installation && this.installation) {
      this.installation.subscribe(installation => {
        this.installationCreated = installation.created;
        this.installationID = installation.id;
        this.restrictDates(installation.created);
        this.loadDonut(installation.id);
      });
    }
  }

  onNavChange(changeEvent: NgbNavChangeEvent) {
    this.route.queryParamMap.subscribe(queryParamMap => {
      if(queryParamMap.get('tab')) {
        this.router.navigate([], {
          relativeTo: this.route, queryParams: {
            tab: +queryParamMap.get('tab'),
            view: changeEvent.nextId
          }
        });
      } else {
        this.router.navigate([], {
          relativeTo: this.route, queryParams: {
            view: changeEvent.nextId
          }
        });
      }
    });
  }


  loadDonut(installationID:number) {
    this.donutChartOptions.series.next([]);
    this.donutChartOptions.labels.next([]);
    this.tableData.next(undefined);
    this.fetchingData.next(true);
    this.chartService.getMetersOverviewTableData(installationID, this.intervalLength, this.intervalString).subscribe(result => {
      if(result!=null) {
        const consumptions = result.table.map(item => item.consumption);
        const names = result.table.map(item => item.name);
        this.donutChartOptions.series.next(consumptions);
        this.donutChartOptions.labels.next(names);
        this.tableData.next(result);
        console.log(result)
        this.dataExists.next(true);
        this.fetchingData.next(false);
      } else {
        console.error("Report data for installation ("+ installationID +") doesn't exist!");
        this.dataExists.next(false);
        this.fetchingData.next(false);
      }
    });
  }

  restrictDates(created: string): void {
    // Year section ------------------------------------------------------------------
    this.years = [];
    const currentYear = new Date().getFullYear();
    const creationYear = parseInt(created.substring(0, 4));
    for ( let i = currentYear; creationYear <= i; i-- ) {
      this.years.push(i);
    }

    // Month section -----------------------------------------------------------------
    this.months = [];
    const currentMonth = new Date().getMonth(); // 0-11
    const creationMonth = parseInt(created.substring(5,7)) - 1; // 0-11
    if(this.intervalSelected.year == currentYear && this.intervalSelected.year == creationYear) {
      for (let i = currentMonth; i >= creationMonth; i--) {
        this.months.push(i+1);
      }
    } else if(this.intervalSelected.year == currentYear){
      for (let i = currentMonth; i >= 0 ; i--) {
        this.months.push(i+1);
      }
    } else if (this.intervalSelected.year == creationYear) {
      for (let i = 11; i >= creationMonth; i--) {
        this.months.push(i+1);
      }
    } else {
      for (let i = 11; i >= 0; i--) {
        this.months.push(i+1);
      }
    }

    // Day section -------------------------------------------------------------------
    this.days = [];
    const currentDate = new Date();
    const creationDate = new Date(created.substring(0,10));

    if (this.intervalSelected.year == currentYear && this.intervalSelected.month - 1 == currentMonth) {
      for (let i = currentDate.getDate(); i >= 1; i--) {
        this.days.push(i);
      }
    } else if (this.intervalSelected.year == creationYear && this.intervalSelected.month - 1 == creationMonth) {
      for (let i = this.daysInMonth(this.intervalSelected.year, this.intervalSelected.month); i >= creationDate.getDate(); i--) {
          this.days.push(i);
      }
    } else {
      for (let i = this.daysInMonth(this.intervalSelected.year, this.intervalSelected.month); i >= 1; i--) {
        this.days.push(i);
      }
    }
  }
  daysInMonth(year: number, month: number): number {
    return new Date(year, month, 0).getDate();
  }
  parseInterval(): void {
    const regexDay = /^\d{4}-\d{2}-\d{2}$/;
    const regexMonth = /^\d{4}-\d{2}$/;
    const regexYear = /^\d{4}$/;

    const yearString = this.intervalSelected.year.toString();
    const monthString = yearString + '-' + String(this.intervalSelected.month).padStart(2, '0');
    const dayString = monthString + '-' + String(this.intervalSelected.day).padStart(2, '0');

    switch (this.intervalLength) {
      case PeriodType.DAY:
        if (!regexDay.test(dayString)) return;
        this.intervalString = dayString;
        break;
      case PeriodType.MONTH:
        if (!regexMonth.test(monthString)) return;
        this.intervalString = monthString;
        break;
      case PeriodType.YEAR:
        if (!regexYear.test(yearString)) return;
        this.intervalString = yearString;
        break;
    }
    this.restrictDates(this.installationCreated);
    this.loadDonut(this.installationID);
  }

  exportToExcel(): void {
    if (this.tableViewComponent) {
      this.tableViewComponent.exportToExcel();
    }
  }

  readonly PeriodType = PeriodType;
}
