import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {UserModel} from '../models/User.model';
import {shareReplay} from 'rxjs/operators';

@Injectable({
  providedIn: "root"
})
export class UserService {

  constructor(
    private http: HttpClient,
  ) {}

  getUserById(id: string): Observable<UserModel> {
    return this.http.get<UserModel>(environment.apiHost + '/api/user/' + id);
  }
  getUsers(): Observable<UserModel[]> {
    return this.http.get<UserModel[]>(environment.apiHost + '/api/user/getall');
  }
  getMe(): Observable<UserModel> {
    return this.http.get<UserModel>(environment.apiHost + '/api/user/getMe').pipe(
      shareReplay(1)
    );
  }
  createUser(i: UserModel): Observable<number> {
    return this.http.post<number>(environment.apiHost + '/api/user/create', i);
  }
  updateUser(i: UserModel): Observable<number> {
    return this.http.post<number>(environment.apiHost + '/api/user/update', i);
  }
  deleteUser(id: string): Observable<unknown> {
    return this.http.delete<unknown>(environment.apiHost + '/api/user/' + id);
  }
}
